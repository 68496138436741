import { format } from 'date-fns';
import utils from '../../util';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let globalContext: any = null;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let globalHooks: any = null;
let browserDebug = false;
const browserAutoAdvance = false;
const debugComponentCid = 'disabled semCampaignSummaryDemographics';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendMessage = (msg: any) => {
  if (browserDebug) {
    switch (msg.type) {
      case 'componentTimeout':
        // eslint-disable-next-line no-console
        console.log('componentTimeout', msg);
        const next = findNextElementToRender();
        if (next) {
          globalHooks({ action: 'setNextComponent', id: next.ucid });
        }
        break;
      default:
        // eslint-disable-next-line no-console
        console.log('sendMessage unhandled', msg);
        break;
    }
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w: any = window as any;
  if (w._generic2_FromPage_hook) {
    w._generic2_FromPage_hook(msg);
  } else {
    // eslint-disable-next-line no-console
    console.log('generic2.sendMessage hook not set');
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const loadUserState = (userState: any) => {
  // add to global render context
  globalContext.userState = userState;

  // if we have 'localized' dates, use them instead of data dates
  const min = utils.tryParseShortDate(userState.localStartDate);
  const max = utils.tryParseShortDate(userState.localEndDate);
  if (min && min.getMonth && min.toString() !== 'Invalid Date') {
    globalContext.friendlyStartDate = format(min, 'MMM d, yyyy');
  }
  if (max && max.getMonth && max.toString() !== 'Invalid Date') {
    globalContext.friendlyEndDate = format(max, 'MMM d, yyyy');
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const receiveMessage = (msg: any) => {
  switch (msg?.event?.action) {
    case 'startRender':
    case 'nextComponent':
      if (msg.event.userState) {
        loadUserState(msg.event.userState);
      }
      // once a component is 'captured', add it to a slide
      updateExportLayout();
      // then figure out if that component wants to render 'more' depending on the current page context
      const next = findNextElementToRender();
      if (next) {
        globalHooks({ action: 'setCurrentComponent', component: next, id: next.ucid });
        let timeout = 5000;
        if (globalContext.layout.fileType === 'XLS') {
          timeout = 30000; // table with tons of data can take a while
        }
        if (typeof next.timeout === 'number') {
          timeout = next.timeout;
        }
        next.renderTimeout = setTimeout(() => {
          next._timeout = true;
          sendMessage({
            type: 'componentTimeout',
            id: next.ucid,
            error: 'render event timed out',
            timeout,
          });
        }, timeout);
      } else {
        sendMessage({
          type: 'allDone',
          pages: globalContext.pdfPages,
        });
      }
      break;
    case 'loadComponent':
      const localComponent = globalContext.layout.elementsByUCID[msg.event.ucid];
      if (!localComponent) {
        // eslint-disable-next-line no-console
        console.log('generic2.loadComponent unmatched component', msg, globalContext.layout.elementsByUCID);
        return;
      }
      globalHooks({ action: 'setCurrentComponent', component: localComponent, id: localComponent.ucid });
      let timeout = 5000;
      if (globalContext.layout.fileType === 'XLS') {
        timeout = 30000; // table with tons of data can take a while
      }
      if (typeof localComponent.timeout === 'number') {
        timeout = localComponent.timeout;
      }
      localComponent.renderTimeout = setTimeout(() => {
        localComponent._timeout = true;
        sendMessage({
          type: 'componentTimeout',
          id: localComponent.ucid,
          error: 'render event timed out',
          timeout,
        });
      }, timeout);
      globalHooks({ action: 'setNextComponent', id: null });
      break;
    default:
      // eslint-disable-next-line no-console
      console.log('generic2.receiveMessage unhandled', msg);
      break;
  }
};

// because the same component can be added multiple times on the same view,
// keep track to prevent duplicating the extra components as well
const extraComponentsHandled = {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleExtraComponents = (localComponent: any, list: any) => {
  if (Array.isArray(localComponent.extraComponents) && !extraComponentsHandled[localComponent.cid]) {
    extraComponentsHandled[localComponent.cid] = true;
    // console.log('extraComponents wanted', merged.extraComponents);
    for (const extra of localComponent.extraComponents) {
      const cid = typeof extra === 'string' ? extra : extra.cid;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let found: any = mergeComponentConfigs({ cid });
      if (found) {
        found.ucid = nextUCID(found, globalContext.layout.elementsByUCID);
        if (typeof extra === 'object') {
          found = { ...found, ...extra };
        }
        globalContext.layout.elementsByUCID[found.ucid] = found;
        list.push(found);
      } else {
        // eslint-disable-next-line no-console
        console.log('could not find extra component', cid, globalContext.layout.elementsByUCID);
      }
    }
  }

  if (Array.isArray(localComponent.subComponents) && localComponent.subComponents.length) {
    // make a copy of this component for each sub component
    // first one goes to this main component
    for (const sub of localComponent.subComponents) {
      if (!localComponent.subComponent) {
        localComponent.subComponent = sub;
        localComponent.ucid = localComponent.component + ':' + sub;
        globalContext.layout.elementsByUCID[localComponent.ucid] = localComponent;
        continue;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const found: any = mergeComponentConfigs({ cid: sub });
      const clone = {
        ...localComponent,
        ...found,
        subComponent: sub,
        ucid: localComponent.component + ':' + sub,
      };
      globalContext.layout.elementsByUCID[clone.ucid] = clone;
      list.push(clone);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleInitMultiGraphs = (localComponent: any, list: any) => {
  if (localComponent.splitOn === 'lineSeriesKeys') {
    // set localComponent to use the first series, then create clones for the others
    delete localComponent.splitOn;

    if (localComponent.lineSeriesKeys?.length > 0) {
      const seriesKey = localComponent.lineSeriesKeys[0];
      localComponent.selectedLineSeriesKey = seriesKey;
      // resets ucid
      delete globalContext.layout.elementsByUCID[localComponent.ucid];
      localComponent.ucid += '#' + seriesKey;
      globalContext.layout.elementsByUCID[localComponent.ucid] = localComponent;
    }
    if (localComponent.lineSeriesKeys?.length > 1) {
      const ucid = localComponent.ucid.split('#')[0];
      for (let i = 1; i < localComponent.lineSeriesKeys.length; i++) {
        const seriesKey = localComponent.lineSeriesKeys[i];
        const copy = {
          ...localComponent,
          ucid: ucid + '#' + seriesKey,
          selectedLineSeriesKey: seriesKey,
          size: { ...localComponent.size }, // deep clone to not update cloned
        };
        globalContext.layout.elementsByUCID[copy.ucid] = copy;
        list.push(copy);
      }
    }
  }
  if (localComponent.splitOn === 'valueKey') {
    // set localComponent to use the first valueKey, then create clones for the others
    delete localComponent.splitOn;

    if (localComponent.valueKey?.length > 0) {
      const valueKey = localComponent.valueKey[0];
      localComponent.selectedValueKey = valueKey;
      // resets ucid
      delete globalContext.layout.elementsByUCID[localComponent.ucid];
      localComponent.ucid += '#' + valueKey;
      globalContext.layout.elementsByUCID[localComponent.ucid] = localComponent;
    }
    if (localComponent.valueKey?.length > 1) {
      const ucid = localComponent.ucid.split('#')[0];
      for (let i = 1; i < localComponent.valueKey.length; i++) {
        const valueKey = localComponent.valueKey[i];
        const copy = {
          ...localComponent,
          ucid: ucid + '#' + valueKey,
          selectedValueKey: valueKey,
          size: { ...localComponent.size }, // deep clone to not update cloned
        };
        globalContext.layout.elementsByUCID[copy.ucid] = copy;
        list.push(copy);
      }
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
const handleRenderedMultiGraphs = (localComponent: any) => {
  // nothing yet
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
const handleInitMultiTables = (localComponent: any, list: any) => {
  const layoutSizes = globalContext.layout.templates?.[0].dynamicPlacements;
  if (!layoutSizes) {
    return;
  }
  // make table bigger if allowed & needed
  if (
    localComponent.itemLimit &&
    localComponent.perHalfPage &&
    localComponent.itemLimit > localComponent.perHalfPage &&
    localComponent.fullPageIfNeeded
  ) {
    localComponent.size.height = layoutSizes.tallCardSize.height;
  }

  if (
    localComponent.size.height === layoutSizes.tallCardSize.height &&
    !localComponent.perPage &&
    localComponent.perFullPage
  ) {
    localComponent.perPage = localComponent.perFullPage;
  } else if (
    localComponent.size.height === layoutSizes.cardSize.height &&
    !localComponent.perPage &&
    localComponent.perHalfPage
  ) {
    localComponent.perPage = localComponent.perHalfPage;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleRenderedMultiTables = (localComponent: any, eventContext: any) => {
  const layoutSizes = globalContext.layout.templates?.[0].dynamicPlacements;
  if (!layoutSizes) {
    return;
  }

  /* if component was customized to have more rows than what fits
     in half height, set to full height. If more than one page is
     needed, and 'multiTable' is set, allow for more pages.
   */
  if (localComponent.multiTable && !(localComponent.itemLimit || localComponent.maxPages)) {
    localComponent.multiTable = false;
  }

  if (localComponent.multiTable && localComponent.maxPages < 2) {
    localComponent.multiTable = false;
  }

  if (localComponent.multiTable && localComponent.itemLimit) {
    if (localComponent.perHalfPage && localComponent.itemLimit <= localComponent.perHalfPage) {
      localComponent.multiTable = false; // not needed
    } else if (
      localComponent.perFullPage &&
      localComponent.itemLimit <= localComponent.perFullPage &&
      localComponent.fullPageIfNeeded
    ) {
      localComponent.multiTable = false; // not needed
    }
  }

  // todo: truncated vs multiTable, should be the same handler

  // summary tables
  if (eventContext.truncated) {
    const ucid = localComponent.ucid.split('#')[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let pageContext: any = null;
    if (!globalContext.multiComponents) {
      globalContext.multiComponents = {};
    }
    if (!globalContext.multiComponents[ucid]) {
      globalContext.multiComponents[ucid] = {
        renderedIndexes: [],
        pages: [],
      };
    }
    pageContext = globalContext.multiComponents[ucid];
    pageContext.renderedIndexes = eventContext.renderedIndexes;

    if (!Array.isArray(eventContext.renderedIndexes) || !eventContext.renderedIndexes.length) {
      localComponent._empty = true;
    }

    // console.log('truncated', eventContext);
    if (!eventContext.completed) {
      // pageContext.renderedIndexes = pageContext.renderedIndexes.concat(eventContext.renderedIndexes);
      pageContext.pages.push({
        page: pageContext.pages.length,
      });
      let maxPages = 50;
      if (typeof localComponent.maxPages === 'number') {
        maxPages = localComponent.maxPages;
      }
      if (maxPages > 1 && pageContext.pages.length < maxPages + 1) {
        const copy = {
          ...localComponent,
          ucid: ucid + '#' + pageContext.pages.length,
          _rendered: false,
          renderedIndexes: pageContext.renderedIndexes,
          showPage: eventContext.page + 1,
          size: { ...localComponent.size }, // deep clone to not update cloned
        };
        globalContext.layout.elementsByUCID[copy.ucid] = copy;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const index = globalContext.layout.orderedComponents.findIndex((c: any) => c.ucid === localComponent.ucid);
        globalContext.layout.orderedComponents.splice(index + 1, 0, copy);
      }
    }
    return;
  }

  // todo: figure out full width if needed
  // localComponent.size.width = layoutSizes.wideCardSize.height;

  if (localComponent.multiTable && !localComponent._empty) {
    const ucid = localComponent.ucid.split('#')[0];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let pageContext: any = null;
    if (!globalContext.multiComponents) {
      globalContext.multiComponents = {};
    }
    if (!globalContext.multiComponents[ucid]) {
      globalContext.multiComponents[ucid] = {
        totalItems: eventContext.totalItems,
        renderedItems: 0,
        totalPages: eventContext.totalPages,
        pages: [],
      };
    }

    pageContext = globalContext.multiComponents[ucid];
    pageContext.renderedItems += eventContext.itemsRenderedCount;
    pageContext.pages.push({
      page: pageContext.pages.length,
      itemCount: eventContext.itemsRenderedCount,
    });

    // if we have more items to render, inject a copy of current component
    let maxPages = 50;
    if (typeof localComponent.maxPages === 'number') {
      maxPages = localComponent.maxPages;
    }
    if (maxPages > 1 && pageContext.totalItems > pageContext.renderedItems && pageContext.pages.length < maxPages + 1) {
      const copy = {
        ...localComponent,
        ucid: ucid + '#' + pageContext.pages.length,
        showPage: pageContext.pages.length,
        _rendered: false,
        size: { ...localComponent.size }, // deep clone to not update cloned
      };
      globalContext.layout.elementsByUCID[copy.ucid] = copy;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const index = globalContext.layout.orderedComponents.findIndex((c: any) => c.ucid === localComponent.ucid);
      globalContext.layout.orderedComponents.splice(index + 1, 0, copy);
    } else if (
      localComponent.perHalfPage &&
      eventContext.itemsRenderedCount <= localComponent.perHalfPage &&
      localComponent.fullPageIfNeeded
    ) {
      localComponent.size.height = layoutSizes.cardSize.height;
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentRendered = (component: any, eventContext: any, waitForComponentId: string) => {
  if (!waitForComponentId || !component || waitForComponentId !== component.ucid) {
    if (browserDebug) {
      // eslint-disable-next-line no-console
      console.log('generic2.componentRendered unexpected', component, eventContext, waitForComponentId);
    }
    return;
  }

  const localComponent = globalContext.layout.elementsByUCID[component.ucid];
  if (!localComponent) {
    // eslint-disable-next-line no-console
    console.log('generic2.rendered unmatched component', component, globalContext.layout.elementsByUCID);
    return;
  }

  if (localComponent._rendered) {
    if (browserDebug) {
      // eslint-disable-next-line no-console
      console.log(
        'generic2.componentRendered already rendered',
        component.ucid,
        component,
        eventContext,
        waitForComponentId,
        localComponent,
        globalContext.layout.elementsByUCID,
      );
    } else {
      // sendMessage({
      //   type: 'trace',
      //   message: `${localComponent.ucid} already rendered`,
      // });
    }
    return;
  }

  localComponent._rendered = true;

  if (browserDebug) {
    // eslint-disable-next-line no-console
    console.log('generic2.componentRendered', component.ucid, eventContext);
  }

  if (localComponent.renderTimeout) {
    clearTimeout(localComponent.renderTimeout);
  }

  if (eventContext?.empty) {
    localComponent._empty = true;
  }

  if (localComponent.multiTable) {
    handleRenderedMultiTables(localComponent, eventContext);
  }

  if (localComponent.multiGraph) {
    handleRenderedMultiGraphs(localComponent);
  }

  let delay = 10;
  if (localComponent.captureDelayMS) {
    delay = localComponent.captureDelayMS;
  }

  setTimeout(() => {
    if (browserDebug) {
      updateExportLayout();
      const next = findNextElementToRender();
      if (next) {
        globalHooks({ action: 'setNextComponent', id: next.ucid });
        if (browserAutoAdvance) {
          setTimeout(() => {
            globalHooks({ action: 'showNextComponent' });
          }, 200);
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('all done', globalContext.pdfPages, globalContext.layout.elementsByUCID);
      }
    } else {
      // // eslint-disable-next-line no-console
      // console.log('generic2.captureComponent', component.ucid, eventContext, delay);
      sendMessage({
        type: 'captureComponent',
        component: localComponent,
        context: eventContext,
      });
    }
  }, delay);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const init = (context: any, hooks: any, debug: boolean) => {
  globalContext = context;
  globalHooks = hooks;
  browserDebug = debug;

  if (browserDebug) {
    // eslint-disable-next-line no-console
    console.log('generic2.init', context);
  }

  const names = ['_generic2_FromPage', '_generic2_ToPage'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const w: any = window as any;
  if (browserDebug) {
    w._generic2_ToPage = receiveMessage;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    w.loadExportUserState = (state: any) => {
      globalContext.userState = state;
    };
  } else {
    w._generic2_ToPage_hook = receiveMessage;
  }
  w._generic2_FromPage = sendMessage;
  context.exportHooks = names;
  // ensure all template/page elements have unique ucid
  // merge elements with their customized attributes
  try {
    PreProcessElements();
  } catch (exp) {
    // eslint-disable-next-line no-console
    console.error('generic2.init', exp);
  }
  return names;
};

const debug = () => {
  if (debugComponentCid) {
    // const localComponent = globalContext.layout.elementsByCID[debugComponentCid];
    const localComponent = globalContext.layout.elementsByUCID[debugComponentCid];
    if (localComponent) {
      // mark all components 'before' this one as rendered
      let reached = false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      globalContext.layout.templates.forEach((template: any) => {
        if (Array.isArray(template.elements) && template.elements.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          template.elements.forEach((c: any) => {
            if (c.ucid === localComponent.ucid) {
              reached = true;
            } else if (!reached) {
              globalContext.layout.elementsByUCID[c.ucid]._rendered = true;
            }
          });
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      globalContext.layout.orderedComponents.forEach((c: any) => {
        if (c.ucid === localComponent.ucid) {
          reached = true;
        } else if (!reached) {
          globalContext.layout.elementsByUCID[c.ucid]._rendered = true;
        }
      });

      globalHooks({ action: 'setCurrentComponent', component: localComponent, id: localComponent.ucid });
      return;
    }
  }
  const next = findNextElementToRender();
  if (next) {
    globalHooks({ action: 'setCurrentComponent', component: next, id: next.ucid });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mergeComponentConfigs = (cpnt: any): any => {
  let sourceComponent = globalContext.layout.elementsByCID[cpnt.cid];
  if (!sourceComponent) {
    // handle component that have been used in existing layouts with a 'wrong' name
    switch (cpnt.cid) {
      case 'googlevideoGeoPerformanceImpressionsMap':
        // eslint-disable-next-line no-console
        console.log(`found a component whose name needs to be updated: ` + `googlevideoGeoPerformanceImpression[s]Map`);
        cpnt.cid = 'googlevideoGeoPerformanceImpressionMap';
        sourceComponent = globalContext.layout.elementsByCID[cpnt.cid];
        break;
      default:
        break;
    }
    if (!sourceComponent) {
      return null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const clean = Object.fromEntries(Object.entries(cpnt).filter(([_, v]) => v != null));
  const ucid = findUCID(cpnt, sourceComponent);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let merged: any = {
    ...sourceComponent,
    ...clean,
    ...sourceComponent.exportOverrides,
    source: sourceComponent,
    customized: clean,
    ucid,
    _disabled: !!clean.disabled || !!sourceComponent.disabled,
  };

  const itemLimitStr = merged.itemLimit;
  let itemLimit = 0;
  if (itemLimitStr) {
    try {
      itemLimit = parseInt(itemLimitStr, 10);
      if (isNaN(itemLimit)) {
        itemLimit = 0;
      }
    } catch (exp) {
      // eslint-disable-next-line no-console
      console.error('itemLimitStr', exp);
    }
  }
  if (itemLimit) {
    merged.itemLimit = itemLimit;
  }

  const layoutSizes = globalContext.layout.templates[0].dynamicPlacements;
  if (
    Array.isArray(globalContext?.layout.templates) &&
    globalContext.layout.templates.length &&
    globalContext.layout.templates[0].dynamicPlacements
  ) {
    // compute size from layout
    merged.size = { height: layoutSizes.cardSize.height, width: layoutSizes.cardSize.width };
    if (merged.breakpoints?.includes('lg12') || merged.fullWidth || merged.fullPage) {
      merged.size.width = layoutSizes.wideCardSize.width;
    }
    if (merged.halfWidth) {
      merged.size.width = layoutSizes.cardSize.width;
    }
    if (merged.fullHeight || merged.fullPage) {
      merged.size.height = layoutSizes.tallCardSize.height;
    }
    if (merged.halfHeight) {
      merged.size.height = layoutSizes.cardSize.height;
    }
    if (merged.autoCanvasWidth && merged.size.width) {
      merged.canvasWidth = sourceComponent.canvasWidth = `${merged.size.width}px`;
    }
    if (merged.autoCanvasHeight && merged.size.height) {
      merged.canvasHeight = sourceComponent.canvasHeight = `${merged.size.height - 60}px`;
    }

    // does this break when we have the same component multiple times?
    sourceComponent.size = merged.size; // send to server
  }

  //  for exports, some source values may win (the export specific values), specific sizes...
  if (sourceComponent.exportOverrides) {
    merged = { ...merged, ...sourceComponent.exportOverrides };
  }

  // with new dynamic exports, we're not using typescript's module declarations
  if (globalContext.layout?.dynamic && !merged.component) {
    merged.component = merged.name;
  }

  // update source of thruth
  globalContext.layout.elementsByUCID[merged.ucid] = merged;
  return merged;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findUCID = (cpnt: any, sourceComponent: any) => {
  // look for what seems to be the most unique id
  let ucid = cpnt.ucid || sourceComponent?.ucid || '';
  if (sourceComponent?.cid && sourceComponent.cid.length > ucid.length) {
    ucid = sourceComponent.cid;
  }
  if (sourceComponent?.id && sourceComponent.id.length > ucid.length) {
    ucid = sourceComponent.id;
  }
  if (cpnt.cid && cpnt.cid.length > ucid.length) {
    ucid = cpnt.cid;
  }
  if (cpnt.id && cpnt.id.length > ucid.length) {
    ucid = cpnt.id;
  }
  if (ucid.length === 0 && cpnt.component) {
    ucid = cpnt.component;
  }
  if (ucid.length === 0 && sourceComponent?.component) {
    ucid = sourceComponent.component;
  }
  return ucid;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const nextUCID = (c: any, hash: any) => {
  let ucid = findUCID(c, null);
  if (hash[ucid]) {
    const a = ucid.split('^');
    const rootUcid = a[0];
    let counter = 0;
    if (a.length > 1) {
      counter = parseInt(a[1], 10);
    }
    counter += 1;
    let check = rootUcid + '^' + counter;
    while (hash[check]) {
      counter += 1;
      check = rootUcid + '^' + counter;
    }
    ucid = check;
  }
  return ucid;
};

const PreProcessElements = (): void => {
  globalContext.layout.elementsByCID = {};
  globalContext.layout.elementsByUCID = {};

  if (Array.isArray(globalContext.layout.templates) && globalContext.layout.templates.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    globalContext.layout.templates.forEach((template: any) => {
      if (Array.isArray(template.elements) && template.elements.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        template.elements.forEach((c: any) => {
          c.ucid = nextUCID(c, globalContext.layout.elementsByUCID);
          c.parentTemplate = template.name;
          c._disabled = !!c.disabled;
          globalContext.layout.elementsByCID[c.cid || c.id || c.ucid] = c;
          globalContext.layout.elementsByUCID[c.ucid] = c;
        });
      }
    });
  }

  if (Array.isArray(globalContext.layout.elements) && globalContext.layout.elements.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    globalContext.layout.elements.forEach((c: any) => {
      c.ucid = nextUCID(c, globalContext.layout.elementsByUCID);
      c._disabled = !!c.disabled;
      globalContext.layout.elementsByCID[c.cid || c.id] = c;
      globalContext.layout.elementsByUCID[c.ucid] = c;
    });
  }

  let reordered = [];
  if (Array.isArray(globalContext?.layoutCustomizations?.config?.components)) {
    const layoutNameSegments = globalContext.exportLayoutName?.split('/');
    let wantSummary = false;
    if (Array.isArray(layoutNameSegments) && layoutNameSegments.length) {
      if (layoutNameSegments[2] === 'googlevideosummary') {
        wantSummary = true;
      }
      if (layoutNameSegments[2] === 'googlevideo' && globalContext.summaryView === 'true') {
        wantSummary = true;
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let components: any[] = [];
    const temp = globalContext.layoutCustomizations.config.components;
    if (Array.isArray(temp) && temp.length > 0) {
      components = temp[0].components;
      if (temp.length > 1) {
        if (wantSummary) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const found = temp.find((x: any) => x.type === 'summaryonly');
          if (found) {
            components = found.components;
          }
        } else {
          const found = temp.find(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (x: any) => x.type === 'agency' || x.type === 'advertiser' || x.type === 'default',
          );
          if (found) {
            components = found.components;
          }
        }
      }
    }
    if (components) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      components.forEach((c: any) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const merged: any = mergeComponentConfigs(c);

        if (!merged) {
          // some components are defined in the export layout, but not in the customization list
          c.ucid = nextUCID(c, globalContext.layout.elementsByUCID);
          globalContext.layout.elementsByUCID[c.ucid] = c;
          return;
        }

        let sameUCID = reordered.find(x => x.ucid === merged.ucid);
        const UCIDs = {};
        reordered.forEach(x => {
          UCIDs[x.cid] = true;
        });
        while (sameUCID) {
          merged.ucid = nextUCID(merged, UCIDs);
          sameUCID = reordered.find(x => x.ucid === merged.ucid);
        }
        globalContext.layout.elementsByUCID[merged.ucid] = merged;

        reordered.push(merged);
        handleExtraComponents(merged, reordered);

        if (merged.multiTable) {
          handleInitMultiTables(merged, reordered);
        }

        if (merged.multiGraph) {
          handleInitMultiGraphs(merged, reordered);
        }

        // re-order as needed
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const movedToEnd = reordered.filter((x: any) => !!x.moveToEnd);
        if (movedToEnd.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          reordered = reordered.filter((x: any) => !x.moveToEnd);
          reordered = reordered.concat(movedToEnd);
        }

        // update source elements as well, to link them back together on the server
        c.ucid = merged.ucid; // ucid can be updated by handleInitXXX
        const sourceComponent = globalContext.layout.elementsByUCID[merged.ucid];
        if (!sourceComponent) {
          if (!c.dynamic) {
            // dynamic components don't exist in layout files
            // eslint-disable-next-line no-console
            console.log('generic2.PreProcessElements sourceComponent not found', merged);
          }
        } else {
          sourceComponent.ucid = merged.ucid;
        }
      });
    }
  } else {
    // eslint-disable-next-line no-console
    console.log('dynamic layout without customizations', globalContext?.layoutCustomizations);
  }
  if (reordered.length) {
    globalContext.layout.orderedComponents = reordered;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findElementByFilter = (source: any[], filter: any): any => {
  if (!Array.isArray(source) || typeof filter !== 'function') {
    return null;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let match: any = null;
  for (let i = 0; i < source.length; i++) {
    const local = globalContext.layout.elementsByUCID[source[i].ucid];
    if (filter(local)) {
      match = local;
      break;
    }
  }
  return match;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notRenderedFilter = (c: any) => c && !c._disabled && !c._rendered && !c._skipped && !c._timeout && !c._empty;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const notPlacedFilter = (c: any) => c && c._rendered && !c._placed && !c._skipped && !c._disabled && !c._empty;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findNextElementToRender = (): any => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let found: any = null;
  if (Array.isArray(globalContext.layout.templates) && globalContext.layout.templates.length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    globalContext.layout.templates.forEach((template: any) => {
      if (found) {
        return;
      }
      if (Array.isArray(template.elements) && template.elements.length > 0) {
        const x = findElementByFilter(template.elements, notRenderedFilter);
        if (x) {
          found = x;
        }
      }
    });
  }

  if (
    !found &&
    Array.isArray(globalContext.layout.orderedComponents) &&
    globalContext.layout.orderedComponents.length > 0
  ) {
    const x = findElementByFilter(globalContext.layout.orderedComponents, notRenderedFilter);
    if (x) {
      const localComponent = globalContext.layout.elementsByUCID[x.ucid];
      if (localComponent) {
        found = localComponent;
      }
    }
  }
  return found;
};

const updateExportLayout = () => {
  /* for PDF:
        - loop through the orderedComponents, fill the pages with rendered components
        - update flags about what's available, if the next component needs to know
        - if there are components who need extra version
            (more tables, more graphs, extra component like geo targeting for maps, ...)
            insert them as appropriate
        - once done, loop through the pages and adjust layout as needed
  */

  const sizes = globalContext.layout.templates?.[0].dynamicPlacements;
  if (!sizes) {
    // eslint-disable-next-line no-console
    console.log('no dynamicPlacements');
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const PlaceComponentInSlides = (c: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shouldBePlacedSeparately = (el: any) => {
      return el.cid.toLowerCase().includes('innovidxp');
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isWide = (el: any) => {
      return el.size.width === sizes.wideCardSize.width;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isTall = (el: any) => {
      return el.size.height === sizes.tallCardSize.height;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isHuge = (el: any) => {
      return el.size.height === sizes.tallCardSize.height && el.size.width === sizes.wideCardSize.width;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isEmpty = (page: any) => {
      return page.elements.length === 0;
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasWide = (page: any) => {
      return (!page.filled[0][0] && !page.filled[0][1]) || (!page.filled[1][0] && !page.filled[1][1]);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasTall = (page: any) => {
      return (!page.filled[0][0] && !page.filled[1][0]) || (!page.filled[0][1] && !page.filled[1][1]);
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasSlot = (page: any) => {
      return !page.filled[0][0] || !page.filled[1][0] || !page.filled[0][1] || !page.filled[1][1];
    };

    const addPage = () => {
      const p = {
        // ordered components to render in this page, each element has its size already computed
        elements: [],
        // flags to keep track of available page slots
        filled: [
          [false, false],
          [false, false],
        ],
      };
      globalContext.pdfPages.push(p);
      return p;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const findSlotInPages = (filter: any, innovidOnly: boolean) => {
      let scopeOfPages = globalContext.pdfPages;
      // DASH-5865: innovidxp components should be placed separately, always on last page, separate from others
      // in the layout, they are ALWAYS in the end. so, find page which starts with
      // innovidxp element (it will always be the last page), or create a new one if no such or current is already filled
      if (innovidOnly)
        scopeOfPages = globalContext.pdfPages.filter(page => page.elements[0].cid.toLowerCase().includes('innovidxp'));
      let p = scopeOfPages.find(filter);
      if (!p) {
        p = addPage();
      }
      return p;
    };

    if (globalContext.layout.fileType === 'PDF') {
      if (!globalContext.pdfPages) {
        globalContext.pdfPages = [];
      }

      let innovidOnly = false;
      if (shouldBePlacedSeparately(c)) {
        innovidOnly = true;
      }
      if (isHuge(c)) {
        const p = findSlotInPages(isEmpty, innovidOnly);
        p.elements.push(c);
        c._placed = true;
        p.filled = [
          [c.ucid, true],
          [true, true],
        ];
        return;
      }
      if (isWide(c)) {
        const p = findSlotInPages(hasWide, innovidOnly);
        if (!p.filled[0][0]) {
          p.filled[0][0] = c.ucid;
          p.filled[0][1] = true;
        } else {
          p.filled[1][0] = c.ucid;
          p.filled[1][1] = true;
        }
        p.elements.push(c);
        c._placed = true;
        return;
      }
      if (isTall(c)) {
        const p = findSlotInPages(hasTall, innovidOnly);
        if (!p.filled[0][0]) {
          p.filled[0][0] = c.ucid;
          p.filled[1][0] = true;
        } else {
          p.filled[0][1] = c.ucid;
          p.filled[1][1] = true;
        }
        p.elements.push(c);
        c._placed = true;
        return;
      }
      const p = findSlotInPages(hasSlot, innovidOnly);
      if (!p.filled[0][0]) {
        p.filled[0][0] = c.ucid;
        p.elements.push(c);
        c._placed = true;
        return;
      }
      if (!p.filled[0][1]) {
        p.filled[0][1] = c.ucid;
        p.elements.push(c);
        c._placed = true;
        return;
      }
      if (!p.filled[1][0]) {
        p.filled[1][0] = c.ucid;
        p.elements.push(c);
        c._placed = true;
        return;
      }
      if (!p.filled[1][1]) {
        p.filled[1][1] = c.ucid;
        p.elements.push(c);
        c._placed = true;
        return;
      }
      // should never hit here
      c._skipped = true;
      // eslint-disable-next-line no-console
      console.log('failed to place ', c.ucid);
      return;
    } else {
      c._skipped = true;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isValidSize = (el: any) => {
    if (!el?.size) {
      return false;
    }
    return (
      (el.size.width === sizes.cardSize.width || el.size.width === sizes.fullCardSize.width) &&
      (el.size.height === sizes.cardSize.height || el.size.height === sizes.fullCardSize.height)
    );
  };

  let safeGuard = 1000;
  while (safeGuard > 0) {
    safeGuard -= 1;
    // find rendered element that isn't in a slide yet
    const c = findElementByFilter(globalContext.layout.orderedComponents, notPlacedFilter);
    if (c) {
      if (!isValidSize(c)) {
        if (browserDebug) {
          // eslint-disable-next-line no-console
          console.log('!isValidSize', c.ucid, c.size);
        }
        c._disabled = true;
        continue;
      }
      PlaceComponentInSlides(c);
      continue;
    }
    // find rendered element that wants to insert more component(s)
    // todo

    // if we reach the end, we haven't found something left to do
    safeGuard = 0;
  }
};

export default {
  init,
  debug,
  sendMessage,
  receiveMessage,
  componentRendered,
};
