
import Vue from 'vue';
import { format, parse } from 'date-fns';
import AllCampaignNames from '../components/exports/allCampaignNames.vue';
import AdPortalFooter from '../components/exports/adPortalFooter.vue';
import AdPortalHeader from '../components/exports/adPortalHeader.vue';
import ExportError from '../components/exports/error.vue';
import ExportDebug from '../components/exports/debug.vue';
import ExportReset from '../components/exports/reset.vue';
import EmptyScheduledExport from '../components/exports/emptyScheduledExport.vue';
import campaignOverview from '../components/charts/overviews/campaignOverview.vue';
import campaignStats from '../components/charts/overviews/campaignStats.vue';
import dmaZipMap from '../components/maps/dmaZipMap.vue';
import conversionMap from '../components/maps/conversionMap.vue';
import genericBarChart from '../components/charts/bar/barChart.vue';
import genericLineChart from '../components/charts/line/lineChart.vue';
import genericLineBarChartNew from '../components/charts/line/wrapperLineCharts.vue';
import genericMap from '../components/maps/genericMap.vue';
import genericPie from '../components/charts/pie/pie.vue';
import genericTable from '../components/charts/tables/table.vue';
import genericTopMetrics from '../components/charts/overviews/topMetrics.vue';
import geofenceMap from '../components/maps/geofenceMap.vue';
import lineAndBarChart from '../components/charts/line/lineAndBarChart.vue';
import progressBarTable from '../components/charts/tables/progressBarTable.vue';
import sideSummary from '../components/charts/overviews/sideSummary.vue';
import siteImpactTable from '../components/charts/tables/siteImpactTable.vue';
import stationMap from '../components/maps/stationMap.vue';
import summaryMap from '../components/summary/components/maps/summary-map.vue';
import tacticTables from '../components/summary/components/tacticTables/colorLabel.vue';
import textCampaignSummary from '../components/charts/overviews/textCampaignSummary.vue';
import summaryController from '../components/summary/summaryController.vue';
import mapWrapper from '../components/maps/mapWrapper.vue';
import mapSummaryOverlay from '../components/summary/layouts/mapSummaryOverlay.vue';
import googlevideoSummaryView from '../components/tacticSummaryViews/googlevideo.vue';
import targetingList from '../components/charts/list/campaignTargeting/targetingList.vue';
import slingNetworkLogosList from '../components/charts/list/slingNetworkLogosList.vue';
import tableList from '../components/charts/tables/tableList.vue';
import vennDiagram from '../components/charts/vennDiagram/vennDiagram.vue';
import benchmarksChart from '../components/charts/overviews/benchmarksChart.vue';

import utils from '../../util';
import { allThemes } from '../../plugins/vuetify/themes/themePlugin';
import generic2 from '../../lib/export/generic2';
import { C360Provider, defineAbilityFor } from '@c360/ui';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let waitForComponentTimer: any;

// DEBUG TIP: change this index based on which component to show in web view
const debugComponentId = '';
const debugComponentCid = '';
const debugExportContext = {};
// const debugExportContext = { tacticTables: { id: 'tacticTables', truncated: true, visible: 3, hidden: 3 } };

let eventFromPageToExportHandlerName = '_eventFromPage';
let eventFromExportToPageHandlerName = '_eventToPage';

export default Vue.extend({
  components: {
    campaignOverview,
    campaignStats,
    dmaZipMap,
    conversionMap,
    EmptyScheduledExport,
    ExportDebug,
    ExportError,
    AdPortalFooter,
    AdPortalHeader,
    ExportReset,
    AllCampaignNames,
    genericBarChart,
    genericLineChart,
    genericLineBarChartNew,
    genericMap,
    genericPie,
    genericTable,
    genericTopMetrics,
    geofenceMap,
    lineAndBarChart,
    progressBarTable,
    sideSummary,
    siteImpactTable,
    stationMap,
    summaryMap,
    tacticTables,
    textCampaignSummary,
    summaryController,
    mapWrapper,
    mapSummaryOverlay,
    googlevideoSummaryView,
    slingNetworkLogosList,
    targetingList,
    tableList,
    vennDiagram,
    benchmarksChart,
    C360Provider,
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: (): any => ({
    browserDebug: false,
    componentsById: {},
    componentsByInternalId: {},
    currentExportComponent: null,
    currentExportContext: null,
    currentPageConfig: null,
    errorMessage: null,
    exportData: null,
    loading: false,
    waitForComponentId: null,
    resettingExportComponent: false,
    debugNextComponent: null,
    refreshToken: '',
    accessToken: '',
    productId: 5,
    themeCode: null,
  }),
  mounted(): void {
    this.sendPageEvent({ type: 'mounted' });
    const token = this.$route.params.token;
    if (!token) {
      this.errorMessage = 'not a valid export page';
      this.sendPageEvent({ type: 'error', message: 'missing token', exit: true });
      return;
    }

    this.loading = true;
    try {
      switch (this.$route.query?._exportAction) {
        case 'initScheduledExport':
          // this.sendPageEvent({ type: 'debug', message: 'initScheduledExport', exit: true });
          this.$store
            .dispatch('getTacticsGQL', { token })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((data: any): void => {
              if (data) {
                // this.$store.dispatch('setLayout', layout).then().catch();
                this.exportData = {
                  exportAction: this.$route.query?._exportAction,
                  advertiserId: this.$route.query.advertiserId,
                  layouts: data,
                };
                this.sendPageEvent({
                  type: 'layoutsLoaded',
                  layouts: data,
                });
                return;
              }

              // eslint-disable-next-line no-console
              console.log(`getExportData getLayoutsGQL error: no layout`);
              this.errorMessage = 'failed to get layout';
              this.sendPageEvent({ type: 'error', message: 'failed to get layout', exit: true });
            })
            .catch((error: Error) => {
              // eslint-disable-next-line no-console
              console.log(`getExportData getLayoutsGQL error: ${error.message}`, error);
              this.errorMessage = error.message || 'failed to get layout';
              this.sendPageEvent({ type: 'error', message: error.message || 'failed to get layout', exit: true });
            });
          return;
        default:
          if (this.$route.query?._exportAction) {
            // eslint-disable-next-line no-console
            console.error('unhandled _exportAction', this.$route.query._exportAction);
          }
          break;
      }

      this.$store
        .dispatch('getExportData', { token })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((data: any) => {
          if (!data || data?.error) {
            this.errorMessage = data?.error || 'failed to get data';
            // eslint-disable-next-line no-console
            console.log(`getExportData error: no data ${data.error}`);
            this.sendPageEvent({ type: 'error', message: `getExportData: [${this.errorMessage}]`, exit: true });
            return;
          }

          this.themeCode = data?.rootDomain?.split('.')?.[0] || 'agency';

          const afterLayout = () => {
            this.renderExportDataVariables();
            let theme;
            Object.keys(allThemes).forEach((k: string) => {
              const t = allThemes[k];
              if (t.name === data?.themeName) {
                theme = t;
              }
            });
            if (theme) {
              this.Theme.set(theme);
            }
            this.sendContextToServer(this.exportData, theme);
          };

          if (!data.layout?.dynamic) {
            this.exportData = {
              ...data,
              ...this.computeExportProperties(data),
            };
            afterLayout();
            return;
          }

          let agency = '';

          const search = new URLSearchParams(window.location.search);
          const domainOverride = search.get('domainOverride');
          if (domainOverride) {
            agency = utils.getAgencyFromURL(domainOverride);
          } else {
            agency = utils.getAgencyFromURL();
          }

          const tactic = data.tab || 'summary';

          // get  default layout
          this.$store
            .dispatch('getLayoutsGQL', { tactic, token: data.graphToken })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((layout: any): void => {
              if (!layout) {
                // eslint-disable-next-line no-console
                console.log(`no default layout for user`);
              }
              this.$store
                .dispatch('setDefaultLayout', layout)
                .then(() => {
                  this.$store
                    .dispatch('getLayoutsGQL', {
                      agency,
                      advertiserId: data.advertiserId,
                      tactic: tactic,
                      token: data.graphToken,
                    })
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .then((layout: any): void => {
                      if (layout) {
                        this.$store.dispatch('setLayout', layout).then().catch();
                        this.exportData = {
                          ...data,
                          layoutCustomizations: layout?.layoutCustomizations,
                          ...this.computeExportProperties(data),
                        };
                        afterLayout();
                        return;
                      }

                      // eslint-disable-next-line no-console
                      console.log(`getExportData getLayoutsGQL error: no layout`);
                      this.errorMessage = 'failed to get layout';
                      this.sendPageEvent({ type: 'error', message: 'failed to get layout', exit: true });
                    })
                    .catch((error: Error) => {
                      // eslint-disable-next-line no-console
                      console.log(`getExportData getLayoutsGQL error: ${error.message}`, error);
                      this.errorMessage = error.message || 'failed to get customized layout';
                      this.sendPageEvent({
                        type: 'error',
                        message: error.message || 'failed to get customized layout',
                        exit: true,
                      });
                    });
                })
                .catch((error: Error) => {
                  // eslint-disable-next-line no-console
                  console.log(`getExportData setDefaultLayout error: ${error.message}`, error);
                  this.errorMessage = error.message || 'failed to set default layout';
                  this.sendPageEvent({
                    type: 'error',
                    message: error.message || 'failed to set default layout',
                    exit: true,
                  });
                });
            })
            .catch((error: Error) => {
              // eslint-disable-next-line no-console
              console.log(`getExportData getLayoutsGQL error: ${error.message}`, error);
              this.errorMessage = error.message || 'failed to get default layout';
              this.sendPageEvent({
                type: 'error',
                message: error.message || 'failed to get default layout',
                exit: true,
              });
            });
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          // eslint-disable-next-line no-console
          console.log(`getExportData error: ${error.message}`, error);
          this.errorMessage = error.message || 'failed to get data';
          this.sendPageEvent({ type: 'error', message: error.message || 'failed to get data', exit: true });
        })
        .finally(() => {
          this.loading = false;
        });
    } catch (exp) {
      // eslint-disable-next-line no-console
      console.log(`getExportData exception: ${exp.message}`);
      this.errorMessage = exp.message || 'failed to get data';
      this.sendPageEvent({ type: 'error', message: exp.message || 'failed to get data', exit: true });
    }
  },
  beforeDestroy(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const w: any = window as any;
    delete w[eventFromPageToExportHandlerName];
    delete w[eventFromExportToPageHandlerName];
  },
  computed: {
    ability() {
      return defineAbilityFor({
        isSuperUser: false,
        isAgencyAdmin: false,
        products: [],
        permissions: ['HIDE_HELP_CENTER'],
        activeAgencyName: '',
        tenantsCount: 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
    findComponent(): string {
      if (this.resettingExportComponent) {
        return this.$options.components['ExportReset'];
      }

      if (this.errorMessage) {
        return 'ExportError';
      }

      const componentName = this.currentExportComponent.component || this.currentExportComponent.cid;

      if (this.$options && this.$options.components) {
        if (this.$options.components[componentName]) {
          return componentName;
        }
        const localName = `Export${componentName}`;
        if (this.$options.components[localName]) {
          return localName;
        }
      }

      // this.errorMessage = `component not found: ${componentName}`;
      this.sendPageEvent({ type: 'error', message: `component not found: ${componentName}` });

      if (this.browserDebug) {
        // eslint-disable-next-line no-console
        console.error('component not found', componentName, this.currentExportComponent);
      }

      return 'error';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentCustomCSS(): any {
      if (this.currentExportComponent?.size) {
        return `height:${this.currentExportComponent.size.height}px;width:${this.currentExportComponent.size.width}px`;
      }
      return '';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentAttributes(): any {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      const { notes, ...cfg } = this.currentExportComponent;
      return {
        isExporting: true,
        isExportDynamic: this.exportData.layout?.dynamic,
        exportData: this.exportData,
        errorMessage: this.errorMessage,
        sectionConfig: { ...this.currentPageConfig },
        componentConfig: { ...this.currentExportComponent },
        theme: this.theme,
        exportContext: { ...this.currentExportContext },
        ...cfg,
      };
    },
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
    isDynamicLayout(): boolean {
      return !!this.exportData?.layout?.dynamic;
    },
    isDynamicExport(): boolean {
      return this.$route.query?.dynamicExport === 'true';
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customExportHandler(): any {
      if (!this.isDynamicExport) {
        return null;
      }
      if (this.exportData.layout?.engine === 'generic2') {
        return generic2;
      }
      if (utils.isLocalDev() && this.exportData.layout?.devEngine === 'generic2') {
        return generic2;
      }
      return null;
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportHandlerHooks(hookAction: any): void {
      switch (hookAction?.action) {
        case 'showNextComponent':
          this.goDebugNextComponent();
          break;
        case 'setCurrentComponent':
          this.resettingExportComponent = true;
          setTimeout(() => {
            this.resettingExportComponent = false;
            this.currentExportComponent = hookAction.component;
            this.waitForComponentId = hookAction.id;
          }, 10);
          break;
        case 'setNextComponent':
          this.debugNextComponent = hookAction.id
            ? {
                event: {
                  action: 'loadComponent',
                  ucid: hookAction.id,
                },
                context: {},
              }
            : null;
          break;
        default:
          // eslint-disable-next-line no-console
          console.error('unhandled handler hook action', hookAction);
          break;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    computeExportSubtasks(context: any): void {
      if (!context?.initContext?.ScheduledReport) {
        if (this.browserDebug) {
          // eslint-disable-next-line no-console
          console.log('computeExportSubtasks: error', 'empty ScheduledReport', context);
        }
        this.sendPageEvent({ type: 'error', message: 'empty ScheduledReport', exit: true });
        return;
      }
      if (!Array.isArray(context.layouts)) {
        if (this.browserDebug) {
          // eslint-disable-next-line no-console
          console.log('computeExportSubtasks: error', 'empty layouts', context);
        }
        this.sendPageEvent({ type: 'error', message: 'empty layouts', exit: true });
        return;
      }

      // foreach each product in initContext.ScheduledReport.Products, create a task
      let products = [];
      if (typeof context.initContext.ScheduledReport.Products === 'string') {
        products = context.initContext.ScheduledReport.Products.split(',');
      } else if (Array.isArray(context.initContext.ScheduledReport.Products)) {
        products = context.initContext.ScheduledReport.Products;
      }

      if (products.length === 0) {
        if (this.browserDebug) {
          // eslint-disable-next-line no-console
          console.log('computeExportSubtasks: error', 'empty product list', context);
        }
        this.sendPageEvent({ type: 'error', message: 'empty product list', exit: true });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const tasks: any[] = [];
      products.forEach((p: string) => {
        const low = p.toLowerCase().trim(); // may need to be translated from the product name
        let fileFormat = 'pdf';
        if (context.initContext.ScheduledReport.Format === 'XLS') {
          fileFormat = 'xls';
        }
        if (context.initContext.ScheduledReport.Format === 'PPT') {
          fileFormat = 'qbr';
        }
        let useLayout = `export/${fileFormat}/${low}`;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const foundLayout = context.layouts?.find((l: any) => l.id === low);
        if (foundLayout) {
          if (fileFormat === 'pdf') {
            if (!foundLayout.pdfExportLocalOnly && !foundLayout.pdfExportDevOnly) {
              useLayout = foundLayout.pdfExport || useLayout;
            }
          } else if (fileFormat === 'xls') {
            if (!foundLayout.xlsExportLocalOnly && !foundLayout.xlsExportDevOnly) {
              useLayout = foundLayout.xlsExport || useLayout;
            }
          } else if (fileFormat === 'qbr') {
            if (!foundLayout.pptExportLocalOnly && !foundLayout.pptExportDevOnly) {
              useLayout = foundLayout.pptExport || useLayout;
            }
          }
        }
        let campaignType = p;
        let tab = low;
        switch (low) {
          case 'simpgeofence':
            tab = 'simpgeofence';
            campaignType = 'SIMPGEOFENCE';
            break;
          case 'display':
            tab = 'display';
            campaignType = 'DISPLAY';
            break;
          case 'gtdisplay':
            tab = 'gtdisplay';
            campaignType = 'GTDISPLAY';
            break;
          case 'video':
          case 'digitalvideo':
            tab = 'digitalvideo';
            campaignType = 'VIDEO';
            break;
          case 'ott':
            tab = 'ott';
            campaignType = 'OTT';
            break;
          case 'preroll':
            tab = 'preroll';
            campaignType = 'PREROLL';
            break;
          case 'sem':
            tab = 'sem';
            campaignType = 'SEM';
            break;
          case 'social':
            tab = 'social';
            campaignType = 'SOCIAL';
            break;
          case 'broadcast':
            tab = 'broadcast';
            campaignType = 'BROADCAST';
            break;
          case 'ga':
            tab = 'ga';
            campaignType = 'GA';
            break;
          case 'emailsi':
          case 'siteimpact':
            tab = 'emailsi';
            campaignType = 'SITEIMPACT';
            useLayout = `export/${fileFormat}/emailsi`;
            break;
          case 'googlesearch':
            tab = 'googlesearch';
            campaignType = 'GOOGLESEARCH';
            break;
          case 'calltracking':
            tab = 'calltracking';
            campaignType = 'CALLTRACKING';
            break;
          case 'empty':
            tab = 'empty';
            useLayout = `export/${fileFormat}/empty`;
            break;
          case 'audio':
            tab = 'audio';
            campaignType = 'AUDIO';
            break;
          case 'gamdisplay':
            tab = 'gamdisplay';
            campaignType = 'GAMDISPLAY';
            break;
          case 'fbinsights':
            tab = 'fbinsights';
            campaignType = 'FBINSIGHTS';
            break;
          case 'gtvideo':
            tab = 'gtvideo';
            campaignType = 'GTVIDEO';
            break;
          case 'linear':
            tab = 'linear';
            campaignType = 'LINEAR';
            break;
          case 'gamvideo':
            tab = 'gamvideo';
            campaignType = 'GAMVIDEO';
            break;
          case 'googlevideo':
            tab = 'googlevideo';
            campaignType = 'GOOGLEVIDEO';
            break;
          default:
            // eslint-disable-next-line no-console
            console.log('warning, unmatched product', low);
            // let it go through, but chances are it will fail
            // let's list all tested/supported products in this switch
            break;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const task: any = {
          id: context.initContext.ScheduledReport.PRPPID,
          email: context.initContext.ScheduledReport.CreatedBy,
          layout: useLayout,
          start: context.initContext.ReportStartDate,
          end: context.initContext.ReportEndDate,
          campaigndaterange: context.initContext.ScheduledReport.campaignDateRange,
          theme: context.initContext.ScheduledReport.theme,
          rootdomain: context.initContext.ScheduledReport.RootDomain || this.Theme.rootDomain,
          agency: context.initContext.ScheduledReport.AgencyPartner,
          subagency: context.initContext.ScheduledReport.SubAgencyPartner,
          summaryView: context.initContext.ScheduledReport.summaryView,
          view: context.initContext.ScheduledReport.view,
          product: p,
          campaigntype: campaignType,
          tab,
          // iat -> todo, server side
        };

        // handle 'all time', and if not specified, use the same date range for campaign data range
        if (context.initContext.ScheduledReport.ReportDateRangeKind === 'AllTime') {
          task.campaigndaterange = 'alltime';
          task.daterange = 'alltime';
        } else if (!task.campaigndaterange) {
          task.campaignstartdate = task.start;
          task.campaignenddate = task.end;
          task.campaigndaterange = 'customRange';
        }

        // pass the list of campaigns as a CSV
        if (
          Array.isArray(context.initContext.ScheduledReport.Campaigns) &&
          context.initContext.ScheduledReport.Campaigns.length
        ) {
          task.campaigns = context.initContext.ScheduledReport.Campaigns.join(',');
        } else if (typeof context.initContext.ScheduledReport.Campaigns === 'string') {
          task.campaigns = context.initContext.ScheduledReport.Campaigns;
        }
        // console.log('task', tasks);
        tasks.push(task);
      });
      this.sendPageEvent({
        type: 'exportSubtasks',
        tasks,
        layouts: context.layouts,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mostRelevantComponentID(c: any): string {
      if (c.ucid) {
        return c.ucid;
      }
      if (c.cid && !c.id) {
        return c.cid;
      }
      if (c.id && !c.cid) {
        return c.id;
      }
      if (!c.id && !c.cid) {
        return c.component;
      }
      if (c.cid.length > c.id.length) {
        return c.cid;
      }
      return c.id;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    markComponents(context: any, counter: number) {
      if (context?.layout?.pages && Array.isArray(context.layout.pages) && context.layout.pages.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        context.layout.pages.forEach((p: any) => {
          if (Array.isArray(p?.elements) && p.elements.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            p.elements.forEach((c: any) => {
              counter += 1;
              const ucid = this.mostRelevantComponentID(c);

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const marked: any = {
                ...c,
                ucid,
                _id: '_' + counter,
              };

              // update source elements as well, to link them back together on the server
              c._id = marked._id;
              this.componentsByInternalId[marked._id] = marked;
              this.componentsById[ucid] = marked;
            });
          }
        });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    markDynamicComponents(context: any, counter: number) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mergeComponentConfigs = (cpnt: any, sourceComponents: any): any => {
        let sourceComponent = sourceComponents[cpnt.cid];
        if (!sourceComponent) {
          // handle component that have been used in existing layouts with a 'wrong' name
          switch (cpnt.cid) {
            case 'googlevideoGeoPerformanceImpressionsMap':
              // eslint-disable-next-line no-console
              console.log(
                'found a component whose name needs to be updated: googlevideoGeoPerformanceImpression[s]Map',
              );
              cpnt.cid = 'googlevideoGeoPerformanceImpressionMap';
              sourceComponent = sourceComponents[cpnt.cid];
              break;
            default:
              break;
          }
          if (!sourceComponent) {
            return null;
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const clean = Object.fromEntries(Object.entries(cpnt).filter(([_, v]) => v != null));
        const cpntId = this.mostRelevantComponentID(cpnt);
        const sourceId = this.mostRelevantComponentID(sourceComponent);
        // looking for the most unique id
        const ucid = this.mostRelevantComponentID({
          component: sourceComponent.component || cpnt.component,
          ucid: cpnt.ucid,
          cid: sourceId,
          id: cpntId,
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let merged: any = {
          ...sourceComponent,
          ...clean,
          ...sourceComponent.exportOverrides,
          source: sourceComponent,
          customized: clean,
          ucid,
        };

        if (
          this.isDynamicLayout &&
          Array.isArray(context?.layout.templates) &&
          context.layout.templates.length &&
          context.layout.templates[0].dynamicPlacements
        ) {
          const layoutSizes = context.layout.templates[0].dynamicPlacements;
          // compute size from layout
          merged.size = { height: layoutSizes.cardSize.height, width: layoutSizes.cardSize.width };
          if (merged.multiPageIfNeeded) {
            // todo: set multiPage if number of items is more than perPage
          }
          if (merged.fullHeightIfNeeded) {
            // todo: set fullHeight if number of items is more than perPage
          }
          if (merged.breakpoints?.includes('lg12') || merged.fullWidth || merged.fullPage) {
            merged.size.width = layoutSizes.wideCardSize.width;
          }
          if (merged.fullHeight || merged.fullPage) {
            merged.size.height = layoutSizes.tallCardSize.height;
          }
          if (merged.autoCanvasWidth && merged.size.width) {
            merged.canvasWidth = sourceComponent.canvasWidth = `${merged.size.width}px`;
          }
          if (merged.autoCanvasHeight && merged.size.height) {
            merged.canvasHeight = sourceComponent.canvasHeight = `${merged.size.height - 60}px`;
          }
          sourceComponent.size = merged.size; // send to server
        }

        //  for exports, some source values may win (the export specific values), specific sizes...
        if (sourceComponent.exportOverrides) {
          merged = { ...merged, ...sourceComponent.exportOverrides };
        }

        // with new dynamic exports, we're not using typescript's module declarations
        if (context.layout?.dynamic && !merged.component) {
          merged.component = merged.name;
        }
        return merged;
      };

      const hash = {};
      if (context?.layout?.pages && Array.isArray(context.layout.pages) && context.layout.pages.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        context.layout.pages.forEach((p: any) => {
          if (Array.isArray(p?.elements) && p.elements.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            p.elements.forEach((c: any) => {
              if (c.ucid) {
                hash[c.ucid] = c;
              }
              if (c.cid) {
                hash[c.cid] = c;
              }
              if (c.id) {
                hash[c.id] = c;
              }
            });
          }
        });
      }

      if (context?.layout?.elements && Array.isArray(context.layout.elements) && context.layout.elements.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        context.layout.elements.forEach((c: any) => {
          if (c.ucid) {
            hash[c.ucid] = c;
          }
          if (c.cid) {
            hash[c.cid] = c;
          }
          if (c.id) {
            hash[c.id] = c;
          }
        });
      }

      const reordered = [];
      if (Array.isArray(context?.layoutCustomizations?.config?.components)) {
        const layoutNameSegments = this.exportData.exportLayoutName?.split('/');
        let wantSummary = false;
        if (Array.isArray(layoutNameSegments) && layoutNameSegments.length) {
          if (layoutNameSegments[2] === 'googlevideosummary') {
            wantSummary = true;
          }
          if (layoutNameSegments[2] === 'googlevideo' && this.exportData.summaryView === 'true') {
            wantSummary = true;
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let components: any[] = [];
        const temp = context.layoutCustomizations.config.components;
        if (Array.isArray(temp) && temp.length > 0) {
          components = temp[0].components;
          if (temp.length > 1) {
            if (wantSummary) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const found = temp.find((x: any) => x.type === 'summaryonly');
              if (found) {
                components = found.components;
              }
            } else {
              const found = temp.find(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (x: any) => x.type === 'agency' || x.type === 'advertiser' || x.type === 'default',
              );
              if (found) {
                components = found.components;
              }
            }
          }
        }
        if (components) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          components.forEach((c: any) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const merged: any = mergeComponentConfigs(c, hash);

            if (!merged) {
              // console.log('!merged', c.cid, c, hash);
              const ucid = this.mostRelevantComponentID(c);
              if (ucid) {
                // some components are defined in the export layout, but not in the customization list
                this.componentsById[ucid] = c;
              }
              return;
            }
            counter += 1;
            merged._id = '_' + counter;

            // update source elements as well, to link them back together on the server
            const sourceComponent = hash[c.cid];
            sourceComponent._id = merged._id;
            c._id = merged._id;

            this.componentsByInternalId[merged._id] = merged;
            this.componentsById[merged.ucid] = merged;
            if (c.id) {
              this.componentsById[c.id] = merged; // dynamic IDs for added components by customizations
            } else {
              // eslint-disable-next-line no-console
              console.log('component without an ID', c, merged);
            }

            reordered.push(merged);

            if (Array.isArray(merged.extraComponents)) {
              // console.log('extraComponents wanted', merged.extraComponents);
              for (const extra of merged.extraComponents) {
                const cid = typeof extra === 'string' ? extra : extra.cid;
                if (this.componentsById[cid]) {
                  // eslint-disable-next-line no-console
                  console.log('already added extra component', extra);
                  continue;
                }
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const found: any = mergeComponentConfigs({ cid }, hash);
                if (found) {
                  counter += 1;
                  found._id = '_' + counter;
                  this.componentsByInternalId[found._id] = found;
                  if (!this.componentsById[found.ucid]) {
                    this.componentsById[found.ucid] = found;
                  }
                  this.componentsById[cid] = found;
                  // todo: will we be able to customize extra elements
                  reordered.push(found);
                } else {
                  // eslint-disable-next-line no-console
                  console.log('could not find extra component', cid, hash);
                }
              }
            }

            if (Array.isArray(merged.subComponents) && merged.subComponents.length) {
              // make a copy of this component for each sub component
              // first one goes to this main component
              for (const sub of merged.subComponents) {
                if (!merged.subComponent) {
                  merged.subComponent = sub;
                  merged.ucid = merged.component + ':' + sub;
                  this.componentsById[merged.ucid] = merged;
                  continue;
                }
                counter += 1;
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const found: any = mergeComponentConfigs({ cid: sub }, hash);
                const clone = {
                  ...merged,
                  ...found,
                  subComponent: sub,
                  _id: '_' + counter,
                  ucid: merged.component + ':' + sub,
                };

                this.componentsByInternalId[clone._id] = clone;
                this.componentsById[clone.ucid] = clone;
                if (!this.componentsById[sub]) {
                  this.componentsById[sub] = clone;
                }
                reordered.push(clone);
              }
            }
          });
        }
      } else {
        // eslint-disable-next-line no-console
        console.log('dynamic layout without customizations', context?.layoutCustomizations);
      }
      if (reordered.length) {
        context.layout.orderedComponents = reordered;
      }
      if (Array.isArray(context.layout.pages) && context.layout.pages.length && !context.layout.pages[0].elements) {
        // for now, put the orderedComponents in first page if it doesn't have any elements, to help the exporter
        context.layout.pages[0].elements = reordered;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    markUncustomizedComponents(context: any, counter: number) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const checkComponent = (cpnt: any): any => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const clean = Object.fromEntries(Object.entries(cpnt).filter(([_, v]) => v != null));
        const ucid = this.mostRelevantComponentID(cpnt);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let merged: any = {
          ...clean,
          ...cpnt.exportOverrides,
          ucid,
        };

        if (
          this.isDynamicLayout &&
          Array.isArray(context?.layout.templates) &&
          context.layout.templates.length &&
          context.layout.templates[0].dynamicPlacements
        ) {
          const layoutSizes = context.layout.templates[0].dynamicPlacements;
          // compute size from layout
          merged.size = { height: layoutSizes.cardSize.height, width: layoutSizes.cardSize.width };
          if (merged.multiPageIfNeeded) {
            // todo: set multiPage if number of items is more than perPage
          }
          if (merged.fullHeightIfNeeded) {
            // todo: set fullHeight if number of items is more than perPage
          }
          if (merged.breakpoints?.includes('lg12') || merged.fullWidth || merged.fullPage) {
            merged.size.width = layoutSizes.wideCardSize.width;
          }
          if (merged.fullHeight || merged.fullPage) {
            merged.size.height = layoutSizes.tallCardSize.height;
          }
          if (merged.autoCanvasWidth && merged.size.width) {
            merged.canvasWidth = cpnt.canvasWidth = `${merged.size.width}px`;
          }
          if (merged.autoCanvasHeight && merged.size.height) {
            merged.canvasHeight = cpnt.canvasHeight = `${merged.size.height - 60}px`;
          }
          cpnt.size = merged.size; // send to server
          // console.log('set size', cpnt.size, merged);
        }

        //  for exports, some source values may win (the export specific values), specific sizes...
        if (cpnt.exportOverrides) {
          merged = { ...merged, ...cpnt.exportOverrides };
        }

        // with new dynamic exports, we're not using typescript's module declarations
        if (context.layout?.dynamic && !merged.component) {
          merged.component = merged.name;
        }
        return merged;
      };

      const hash = {};
      if (context?.layout?.pages && Array.isArray(context.layout.pages) && context.layout.pages.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        context.layout.pages.forEach((p: any) => {
          if (Array.isArray(p?.elements) && p.elements.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            p.elements.forEach((c: any) => {
              counter += 1;
              c.__counter = counter;
              hash[c.cid || c.id] = c;
            });
          }
        });
      }

      if (context?.layout?.elements && Array.isArray(context.layout.elements) && context.layout.elements.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        context.layout.elements.forEach((c: any) => {
          counter += 1;
          c.__counter = counter;
          hash[c.cid || c.id] = c;
        });
      }

      const reordered = [];
      Object.keys(hash).forEach((cid: string) => {
        const c = hash[cid];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const merged: any = checkComponent(c);
        if (!merged) {
          // console.log('!merged', c.cid, c, hash);
          const ucid = this.mostRelevantComponentID(c);
          if (ucid) {
            // some components are defined in the export layout, but not in the customization list
            this.componentsById[ucid] = c;
          }
          return;
        }
        merged._id = '_' + merged.__counter;
        // update source elements as well, to link them back together on the server
        c._id = merged._id;

        this.componentsByInternalId[merged._id] = merged;
        this.componentsById[merged.ucid] = merged;
        if (c.id) {
          this.componentsById[c.id] = merged; // dynamic IDs for added components by customizations
        } else if (c.cid) {
          this.componentsById[c.cid] = merged;
        } else {
          // eslint-disable-next-line no-console
          console.log('component without an ID 2', c, merged);
        }

        reordered.push(merged);
      });

      if (reordered.length) {
        context.layout.orderedComponents = reordered;
      }
      if (Array.isArray(context.layout.pages) && context.layout.pages.length && !context.layout.pages[0].elements) {
        // for now, put the orderedComponents in first page if it doesn't have any elements, to help the exporter
        context.layout.pages[0].elements = reordered;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    computeExportProperties(data: any): object {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const p: any = {};
      if (!data) {
        return p;
      }
      if (data.campaignType) {
        p.campaignType = data.campaignType;
      }
      // set valid sections
      if (data.validSections) {
        this.$store.dispatch('setValidSections', data.validSections.split(','));
      }

      /* switching to show Schedule dates in header
      if (data.layout?.product) {
        let min;
        let max;
        const totals = data.adData[`${data.layout.product}Total`];
        if (totals?.ContractStartDate && totals?.ContractEndDate) {
          try {
            min = parse(totals.ContractStartDate.split(' ')[0].replace(/\//g, '-'), 'MM-dd-yyyy', new Date());
            max = parse(totals.ContractEndDate.split(' ')[0].replace(/\//g, '-'), 'MM-dd-yyyy', new Date());
            // max.setDate(max.getDate() + 1); // we manually added a day
          } catch (err) {
           // eslint-disable-next-line no-console
            console.log(
              `computeExportProperties campaignStartDate/campaignEndDate ${totals.ContractStartDate} ${totals.ContractEndDate} ${err.message}`,
            );
          }
        }
        if (min && min.getMonth && min.toString() !== 'Invalid Date') {
          p.friendlyStartDate = format(min, 'MMM d, yyyy');
        }
        if (max && max.getMonth && max.toString() !== 'Invalid Date') {
          p.friendlyEndDate = format(max, 'MMM d, yyyy');
        }
      } */

      if (data.timeZoneIANA) {
        p.timeZoneIANA = data.timeZoneIANA;
      }

      if (data.exportCampaignStartDate && data.exportCampaignEndDate) {
        p.friendlyStartDate = data.exportCampaignStartDate;
        p.friendlyEndDate = data.exportCampaignEndDate;
        const min = utils.tryParseShortDate(data.exportCampaignStartDate);
        const max = utils.tryParseShortDate(data.exportCampaignEndDate);
        if (min && min.getMonth && min.toString() !== 'Invalid Date') {
          p.friendlyStartDate = format(min, 'MMM d, yyyy');
        }
        if (max && max.getMonth && max.toString() !== 'Invalid Date') {
          p.friendlyEndDate = format(max, 'MMM d, yyyy');
        }
      }

      if (data.exportStartDate && data.exportEndDate) {
        p.friendlyStartDate = data.exportStartDate;
        p.friendlyEndDate = data.exportEndDate;
        const min = utils.tryParseShortDate(data.exportStartDate);
        const max = utils.tryParseShortDate(data.exportEndDate);
        if (min && min.getMonth && min.toString() !== 'Invalid Date') {
          p.friendlyStartDate = format(min, 'MMM d, yyyy');
        }
        if (max && max.getMonth && max.toString() !== 'Invalid Date') {
          p.friendlyEndDate = format(max, 'MMM d, yyyy');
        }
      }

      if (data.startDate && data.endDate) {
        p.friendlyStartDate = data.startDate;
        p.friendlyEndDate = data.endDate;
        const min = utils.tryParseShortDate(data.startDate);
        const max = utils.tryParseShortDate(data.endDate);
        if (min && min.getMonth && min.toString() !== 'Invalid Date') {
          p.friendlyStartDate = format(min, 'MMM d, yyyy');
        }
        if (max && max.getMonth && max.toString() !== 'Invalid Date') {
          p.friendlyEndDate = format(max, 'MMM d, yyyy');
        }
      }

      // if we're using 'alltime' date range, show the current campaign dates, that's what's being shown in the date range picker
      if (data.exportDateRange === 'alltime' && data.exportStartDate && data.exportEndDate) {
        p.friendlyStartDate = data.exportCampaignStartDate;
        p.friendlyEndDate = data.exportCampaignEndDate;
        const min = utils.tryParseShortDate(data.exportCampaignStartDate);
        const max = utils.tryParseShortDate(data.exportCampaignEndDate);
        if (min && min.getMonth && min.toString() !== 'Invalid Date') {
          p.friendlyStartDate = format(min, 'MMM d, yyyy');
        }
        if (max && max.getMonth && max.toString() !== 'Invalid Date') {
          p.friendlyEndDate = format(max, 'MMM d, yyyy');
        }
      }

      if (data.campaignDateRange === 'customRange' && data.campaignStartDate && data.campaignEndDate) {
        p.friendlyStartDate = data.campaignStartDate;
        p.friendlyEndDate = data.campaignEndDate;
        const min = utils.tryParseShortDate(data.campaignStartDate);
        const max = utils.tryParseShortDate(data.campaignEndDate);
        if (min && min.getMonth && min.toString() !== 'Invalid Date') {
          p.friendlyStartDate = format(min, 'MMM d, yyyy');
        }
        if (max && max.getMonth && max.toString() !== 'Invalid Date') {
          p.friendlyEndDate = format(max, 'MMM d, yyyy');
        }
      }

      if (
        data.exportDateRange &&
        data.exportDateRange !== 'customRange' &&
        data.exportCampaignStartDate &&
        data.exportCampaignEndDate
      ) {
        p.friendlyStartDate = data.exportCampaignStartDate;
        p.friendlyEndDate = data.exportCampaignEndDate;
        const min = utils.tryParseShortDate(data.exportCampaignStartDate);
        const max = utils.tryParseShortDate(data.exportCampaignEndDate);
        if (min && min.getMonth && min.toString() !== 'Invalid Date') {
          p.friendlyStartDate = format(min, 'MMM d, yyyy');
        }
        if (max && max.getMonth && max.toString() !== 'Invalid Date') {
          p.friendlyEndDate = format(max, 'MMM d, yyyy');
        }
      }

      // use new custom date range dates
      if (
        data.exportDateRange &&
        data.exportDateRange !== 'customRange' &&
        data.exportStartDate &&
        data.exportEndDate
      ) {
        p.friendlyStartDate = data.exportStartDate;
        p.friendlyEndDate = data.exportEndDate;
        const min = utils.tryParseShortDate(data.exportStartDate);
        const max = utils.tryParseShortDate(data.exportEndDate);
        if (min && min.getMonth && min.toString() !== 'Invalid Date') {
          p.friendlyStartDate = format(min, 'MMM d, yyyy');
        }
        if (max && max.getMonth && max.toString() !== 'Invalid Date') {
          p.friendlyEndDate = format(max, 'MMM d, yyyy');
        }
      }

      // todo: revisit this after supporting multiple campaign selection
      if (
        data.campaignIds &&
        data.adData?.CampaignList &&
        Array.isArray(data.adData?.CampaignList) &&
        data.adData?.CampaignList.length === 1 &&
        data.adData?.CampaignList[0].CampaignId === data.campaignIds &&
        !data.tacticsSummary
      ) {
        p.campaign = data.adData.CampaignList[0];
        p.FriendlyName = data.adData.CampaignList[0].FriendlyName;
        p.CampaignSelectionText = p.FriendlyName;
      } else if (data.tacticsSummary) {
        // for summary tactic pages.
        p.CampaignSelectionText = 'Summary';
      } else if (data.campaignIds && data.adData?.CampaignList && Array.isArray(data.adData?.CampaignList)) {
        const idList = data.campaignIds.split(',');
        if (!utils.allowSingleCampaignView(data, true)) {
          p.CampaignSelectionText = `All Campaigns`;
        } else if (idList.length > 1) {
          p.CampaignSelectionText = `${idList.length} Campaigns Selected`;
        } else if (Array.isArray(data.campaigns.Campaign) && data.campaigns.Campaign.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const found = data.campaigns.Campaign.find((x: any) => x.id === data.campaignIds);
          if (found) {
            p.campaign = found;
            p.FriendlyName = found.name;
            p.CampaignSelectionText = p.FriendlyName;
          }
        }
      } else if (!data.campaignIds) {
        p.CampaignSelectionText = `All Campaigns Selected`;
      }

      if (data.tabOverride) {
        p.tabName = utils.tabIdNameMap(data.tabOverride);
        p.CampaignSelectionText = '';
      } else if (data.tab) {
        p.tabName = utils.tabIdNameMap(data.tab);
      }

      // new: use data-api date range when available
      try {
        if (
          data.exportDateRange &&
          data.exportDateRange !== 'customRange' &&
          p.campaign?.AnalyticsType &&
          data.adData?.[p.campaign.AnalyticsType + 'Total']?.DaterangeStart
        ) {
          p.friendlyStartDate = data.adData?.[p.campaign.AnalyticsType + 'Total']?.DaterangeStart;
          p.friendlyEndDate = data.adData?.[p.campaign.AnalyticsType + 'Total']?.DaterangeEnd;
          const min = utils.tryParseShortDate(p.friendlyStartDate);
          const max = utils.tryParseShortDate(p.friendlyEndDate);
          if (min && min.getMonth && min.toString() !== 'Invalid Date') {
            p.friendlyStartDate = format(min, 'MMM d, yyyy');
          }
          if (max && max.getMonth && max.toString() !== 'Invalid Date') {
            p.friendlyEndDate = format(max, 'MMM d, yyyy');
          }
        }
      } catch (exp) {
        // eslint-disable-next-line no-console
        console.log('failed to use data-api dates', exp.message);
      }

      return p;
    },
    renderExportDataVariables() {
      this.exportData.exportPages = [];
      if (Array.isArray(this.exportData.layout?.pages)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.exportData.layout.pages.forEach((p: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const renderedPage: any = { ...p };
          if (Array.isArray(p.columns)) {
            delete renderedPage.columns;
            renderedPage.columns = [];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            p.columns.forEach((c: any) => {
              const x = { ...c };
              delete x.exportTweaks;
              if (typeof x.method === 'string') {
                switch (x.method) {
                  case 'FindLastModifiedDate':
                    delete x.method;
                    if (Array.isArray(this.exportData.campaigns?.Campaign)) {
                      const found = this.exportData.campaigns.Campaign.find(c => c.id === this.exportData.campaignIds);
                      if (found) {
                        x.value = found.LastModifiedDate;
                      }
                    }
                    break;
                  default:
                    // eslint-disable-next-line no-console
                    console.log('renderExportDataVariables unhandled method', x);
                    break;
                }
              }
              if (typeof x.value === 'string') {
                x.value = utils.renderTemplate(x.value, this.exportData);
              }
              if (typeof x.value === 'string' && c.exportTweaks?.parseDate && c.exportTweaks?.formatDate) {
                try {
                  const tmp = parse(x.value, c.exportTweaks.parseDate, new Date());
                  x.value = format(tmp, c.exportTweaks.formatDate);
                } catch (exp) {
                  // eslint-disable-next-line no-console
                  console.log('renderExportDataVariables', c, x, exp);
                }
              }
              if (typeof x.value === 'string' && c.exportTweaks?.stringToNumber) {
                try {
                  x.value = parseFloat(x.value);
                } catch (exp) {
                  // eslint-disable-next-line no-console
                  console.log('renderExportDataVariables', c, x, exp);
                }
              }
              renderedPage.columns.push(x);
            });
          }
          this.exportData.exportPages.push(renderedPage);
        });
      }
    },
    hookBrowserDebug() {
      this.browserDebug = true;
      // console.log('hookBrowserDebug');

      // to debug something outside of the export process
      // here, loading the first template when the page is ready
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fakeServerHandler = (event: any) => {
        if (!event) {
          // eslint-disable-next-line no-console
          console.log('fakeServerHandler, no event');
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const setPageDimensions = (size: any) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
          return new Promise((resolve: any, reject: any) => {
            const host = this.$refs.hostElement;
            if (size?.width) {
              host.$el.style.width = `${size.width}px`;
              host.$el.style.overflowX = 'hidden';
            } else {
              host.$el.style.width = `auto`;
            }
            if (size?.height) {
              host.$el.style.height = `${size.height}px`;
              host.$el.style.overflowY = 'hidden';
            } else {
              host.$el.style.height = `auto`;
            }
            // console.log('setPageDimensions', JSON.stringify(size));
            this.$nextTick(resolve);
          });
        };

        const renderComponentById = (id: string) => {
          const cpnt = this.componentsByInternalId[id];
          if (!cpnt) {
            // eslint-disable-next-line no-console
            console.log(`renderComponentById not found ${id}`, this.componentsByInternalId);
            return;
          }

          // todo: from component or dynamic layout
          setPageDimensions(cpnt.size)
            .then(() => {
              w._eventToPage({
                // loadTemplate: template.name,
                loadComponent: id,
                context: this.currentExportContext,
                size: cpnt.size,
              });
            })
            .catch(exp => {
              // eslint-disable-next-line no-console
              console.log(`renderComponentById ${exp.message} ${id}`);
            });
        };

        const renderComponentByCid = (id: string) => {
          let cid = id;
          let subComponentId;
          if (cid?.includes(':')) {
            const temp = id.split(':');
            if (temp.length === 2 && temp[0] === 'summaryController') {
              subComponentId = temp[1];
              if (temp[1] === 'overlay' || temp[1] === 'mapAndMetrics') {
                cid = 'summaryController:mapAndMetrics';
              } else {
                // second component in layout, the tactic tables
                cid = 'summaryController:tables';
              }
            }
          }

          const cpnt = this.componentsById[cid];
          // console.log('renderComponentByCid', cpnt, cid, this.componentsById);
          if (!cpnt) {
            // eslint-disable-next-line no-console
            console.log(`renderComponentByCid not found ${id}`, this.componentsById);
            return;
          }

          // todo: from component or dynamic layout
          setPageDimensions(cpnt.size)
            .then(() => {
              w._eventToPage({
                // loadTemplate: template.name,
                loadComponent: cpnt._id + (subComponentId ? `:${subComponentId}` : ''),
                context: this.currentExportContext,
                size: cpnt.size,
              });
            })
            .catch(exp => {
              // eslint-disable-next-line no-console
              console.log(`renderComponentByCid ${exp.message} ${id}`);
            });
        };

        // fake latency
        setTimeout(() => {
          switch (event.type) {
            case 'debug':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler debug fired', event.data || event);
              break;
            case 'dataLoaded':
              // eslint-disable-next-line no-console
              console.log('getExportData', this.exportData);

              if (!this.exportData) {
                // eslint-disable-next-line no-console
                console.log('getExportData: dataLoaded error', this.exportData);
                this.sendPageEvent({ type: 'error', message: 'empty exportData', exit: true });
                return;
              }

              if (this.exportData.error) {
                // eslint-disable-next-line no-console
                console.log('getExportData: dataLoaded error', this.exportData);
                this.sendPageEvent({ type: 'error', message: this.exportData.error, exit: true });
                return;
              }

              if (!this.exportData.layout) {
                // eslint-disable-next-line no-console
                console.log('getExportData: no layout', this.exportData);
                this.sendPageEvent({ type: 'error', message: 'missing layout', exit: true });
                return;
              }

              if (!Array.isArray(this.exportData.layout.elements) && !Array.isArray(this.exportData.layout.pages)) {
                // eslint-disable-next-line no-console
                console.log('getExportData: layout has no page/elements', this.exportData.layout);
                this.sendPageEvent({ type: 'error', message: 'missing layout pages/elements', exit: true });
                return;
              }

              this.currentPageConfig = this.exportData.layout?.pages?.[1];
              if (debugExportContext) {
                this.currentExportContext = debugExportContext;
              }

              if (this.customExportHandler) {
                this.customExportHandler.debug();
              } else if (this.exportData.componentId) {
                renderComponentByCid(this.exportData.componentId);
                // special cases
                // if (this.exportData.componentId.includes(':')) {
                //   const temp = this.exportData.componentId.split(':');
                //   if (temp.length === 2 && temp[0] === 'summaryController') {
                //     if (temp[1] === 'overlay') {
                //       // first component in layout, the map overlay
                //       renderComponentById('_1');
                //       break;
                //     }
                //     // second component in layout, the tactic tables
                //     renderComponentById('_2');
                //     break;
                //   }
                // }
                // const el = this.componentsById[this.exportData.componentId];
                // if (el?._id) {
                //   renderComponentById(el._id);
                // } else {
                //   // eslint-disable-next-line no-console
                //   console.log('component (id) not found', this.exportData.componentId, this.componentsById);
                // }
              } else if (debugComponentCid) {
                renderComponentByCid(debugComponentCid);
              } else if (debugComponentId) {
                renderComponentById(debugComponentId);
              }
              // const template = this.exportData.layout.templates[0];
              // w._eventToPage({
              //   loadTemplate: template.name,
              // });
              break;
            case 'mounted':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler mounted fired');
              break;
            case 'layoutsLoaded':
              w._eventToPage({
                action: 'computeExportSubtasks',
                initContext: {
                  Action: 'ScheduledReport',
                  ReportStartDate: '1/1/2020',
                  ReportEndDate: '8/7/2022',
                  ScheduledReport: {
                    HardCoded: 'filexport.vue#1529',
                    Campaigns: ['18210053518'],
                    Products: ['SEM'],
                    ReportDateRangeKind: 'None',
                    PRDSC: 'DLADVERTISER',
                    Format: 'PPT',
                    PRPPID: '602EB96DF7B9D360366A0BF70CB746D8',
                    AgencyPartner: 'Compulse',
                    RootDomain: 'sbganalytics.com',
                    CreatedBy: 'rahuja@sbgtv.com',
                  },
                },
                globalContext: { _pageMounted: true },
                layouts: event.layouts,
              });
              break;
            case 'exportSubtasks':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler exportSubtasks fired', event);
              break;
            case 'error':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler error fired', event);
              break;
            case 'templateReady':
              const tmpl = this.exportData.layout.templates.find(t => t.name === event.name);
              if (!tmpl) {
                // eslint-disable-next-line no-console
                console.log('fakeServerHandler templateReady template not found', event, this.exportData.layout);
                return;
              }
              w._eventToPage({
                loadComponent: tmpl.elements[0]._id,
              });
              break;
            case 'pageReady':
              // eslint-disable-next-line no-console
              console.log('fakeServerHandler pageReady fired');
              break;
            case 'componentReady':
              if (event.error || event.warning) {
                // eslint-disable-next-line no-console
                console.error('fakeServerHandler componentReady warning', event.id, event);
              } else {
                // eslint-disable-next-line no-console
                console.log('fakeServerHandler componentReady fired', event.id);
                // console.log('fakeServerHandler componentReady fired', event.id, event.text);
              }
              // if (event.id === '_1') {
              //   w._eventToPage({
              //     loadComponent: '_2',
              //   });
              // }
              this.debugNextComponent = null;
              if (event.context?.truncated && !event.context?.completed && this.currentExportComponent.multiPage) {
                const ctx = {
                  [this.currentExportComponent.id]: {
                    ...event.context,
                  },
                };
                this.debugNextComponent = {
                  loadComponent: `${this.currentExportComponent._id}#${event.context.page}`,
                  context: ctx,
                  size: this.currentExportComponent.size,
                };
              }
              break;
            default:
              // eslint-disable-next-line no-console
              console.error('unhandled fakeServerHandler', event);
              break;
          }
        }, 10);
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w: any = window as any;
      w._eventFromPage = fakeServerHandler;
    },
    resetAndWaitForComponentRender(component, timeout = 3 * 1000) {
      this.resettingExportComponent = true;
      // console.log('resetAndWaitForComponentRender', component.component);
      setTimeout(() => {
        this.resettingExportComponent = false;
        this.waitForComponentRender(component, timeout);
      }, 10);
    },
    waitForComponentRender(component, timeout = 3 * 1000) {
      // console.log('waitForComponentRender', component.component);
      this.currentExportComponent = component;
      this.waitForComponentId = component._id;
      let check = component.timeout || timeout;
      if (this.exportData?.layout?.fileType === 'XLS') {
        check = 30000; // table with tons of data can take a while
      }
      waitForComponentTimer = setTimeout(() => {
        if (!this.waitForComponentId) {
          return;
        }
        this.sendPageEvent({
          type: 'componentReady',
          id: this.waitForComponentId,
          error: 'render event timed out', // instead, should we warn and capture?
          timeout: check,
        });
        this.waitForComponentId = null;
      }, check);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentRendered(cpnt: any, eventContext: any) {
      if (this.customExportHandler) {
        this.customExportHandler.componentRendered(cpnt, eventContext, this.waitForComponentId);
        return;
      }

      // make sure we're getting the rendered event from the component we're actually waiting for
      if (!this.waitForComponentId || !cpnt || this.waitForComponentId !== cpnt._id) {
        if (this.browserDebug) {
          // eslint-disable-next-line no-console
          console.log('ignored componentRendered', cpnt, eventContext);
        }
        return;
      }

      if (waitForComponentTimer) {
        clearTimeout(waitForComponentTimer);
      }
      let delay = 10;
      if (this.currentExportComponent && this.currentExportComponent.captureDelayMS) {
        delay = this.currentExportComponent.captureDelayMS;
      }
      if (this.browserDebug) {
        // eslint-disable-next-line no-console
        console.log('componentRendered', cpnt._id, cpnt, eventContext);
      }
      if (eventContext.truncated) {
        // console.log('eventContext', JSON.stringify(eventContext));
        // console.log('currentExportContext', JSON.stringify(this.currentExportContext));
        const continueFrom = cpnt.continueFrom;
        eventContext = {
          ...this.currentExportContext?.[cpnt.id],
          ...eventContext,
          continueFrom,
          ucid: cpnt._id_version,
        };

        if (typeof eventContext.page === 'number') {
          if (!eventContext.completed) {
            eventContext.page += 1;
          }
        } else {
          eventContext.page = 1;
        }
      }
      setTimeout(() => {
        this.sendPageEvent({
          type: 'componentReady',
          id: cpnt._id_version || cpnt._id,
          context: eventContext,
        });
      }, delay);
      this.waitForComponentId = null;
    },
    sendContextToServer(data, theme) {
      try {
        if (data.error) {
          // eslint-disable-next-line no-console
          console.log('getExportData: error', data);
          this.sendPageEvent({ type: 'error', message: data.error, exit: true });
          return;
        }

        if (!data.layout) {
          // eslint-disable-next-line no-console
          console.log('getExportData: no layout');
          this.sendPageEvent({ type: 'error', message: 'no layout', exit: true });
          return;
        }

        if (!Array.isArray(data.layout.pages) && !Array.isArray(data.layout.elements)) {
          // eslint-disable-next-line no-console
          console.log('getExportData: layout has no page/elements', data.layout);
          this.sendPageEvent({ type: 'error', message: 'missing layout pages/elements', exit: true });
          return;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const context: any = {
          type: 'dataLoaded',
          layout: data.layout,
          layoutCustomizations: data.layoutCustomizations,
          theme: theme,
          advertiser: data.advertiser,
          adData: data.adData,
          exportPages: data.exportPages,
          user: data.user,
        };
        let exportHooks = ['_eventFromPage', '_eventToPage'];
        if (this.customExportHandler) {
          exportHooks = this.customExportHandler.init(this.exportData, this.exportHandlerHooks, !!this.browserDebug);
        } else {
          // look into layout, assign unique IDs to each renderable component
          let counter = 0;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const markTemplateComponents = (list: any) => {
            if (list && list.elements && Array.isArray(list.elements) && list.elements.length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              list.elements.forEach((c: any) => {
                // eslint-disable-next-line no-plusplus
                c._id = `_${++counter}`;
                this.componentsByInternalId[c._id] = c;
                const ucid = this.mostRelevantComponentID(c);
                if (ucid) {
                  this.componentsById[ucid] = c;
                }
              });
            }
          };
          if (
            context.layout?.templates &&
            Array.isArray(context.layout.templates) &&
            context.layout.templates.length > 0
          ) {
            context.layout.templates.forEach(markTemplateComponents);
          }
          if (Array.isArray(context?.layoutCustomizations?.config?.components)) {
            this.markDynamicComponents(context, counter);
          } else if (context?.layout?.dynamic) {
            this.markUncustomizedComponents(context, counter);
          } else {
            this.markComponents(context, counter);
          }
        }
        this.sendPageEvent(context);
        // send the initial context with the global hooks, then install the custom ones
        if (this.customExportHandler) {
          eventFromPageToExportHandlerName = exportHooks[0];
          eventFromExportToPageHandlerName = exportHooks[1];
        }
      } catch (exp) {
        if (this.browserDebug) {
          // eslint-disable-next-line no-console
          console.log(`sendContextToServer error`, exp);
        } else {
          // eslint-disable-next-line no-console
          console.log(`sendContextToServer error: ${exp.message}`);
        }
        this.sendPageEvent({ type: 'error', message: `sendContextToServer ${exp.message}`, exit: true });
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadLayoutTemplate(tmpl: any) {
      this.waitForRender(() => {
        this.sendPageEvent({
          type: 'templateReady',
          name: tmpl.name,
        });
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    loadLayoutPage(page: any, index: number) {
      this.waitForRender(() => {
        this.sendPageEvent({
          type: 'pageReady',
          index,
        });
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sendPageEvent(event: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w: any = window as any;
      if (!w) {
        return;
      }
      // _eventFromPage is registered by puppeteer into this 'browser', to receive events from this page
      if (!w[eventFromPageToExportHandlerName]) {
        this.hookBrowserDebug();
      }
      if (!w[eventFromExportToPageHandlerName]) {
        // bind receiving events from puppeteer into this page
        w[eventFromExportToPageHandlerName] = this.receiveServerEvent;
      }
      w[eventFromPageToExportHandlerName](event);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    receiveServerEvent(event: any) {
      try {
        if (!event) {
          this.sendPageEvent({
            type: 'debug',
            data: 'empty event from server to browser',
          });
          return;
        }

        if (this.browserDebug) {
          // eslint-disable-next-line no-console
          console.log('receiveServerEvent', event);
        }

        // this.sendPageEvent({
        //   type: 'debug',
        //   data: event,
        // });

        if (event.loadTemplate) {
          const template = this.exportData?.layout?.templates?.find(x => x.name === event.loadTemplate);
          if (!template) {
            this.sendPageEvent({
              type: 'templateNotFound',
              name: template.name,
            });
            return;
          }
          this.loadLayoutTemplate(template);
          return;
        }

        if (typeof event.loadPageIndex === 'number') {
          const page = this.exportData?.layout?.pages[event.loadPageIndex];
          if (!page) {
            this.sendPageEvent({
              type: 'pageNotFound',
              index: event.loadPageIndex,
            });
            return;
          }
          this.loadLayoutPage(page, event.loadPageIndex);
          return;
        }

        if (event.resetComponent) {
          this.waitForComponentId = null;
          this.currentExportComponent = null;
          return;
        }

        if (event.loadComponent) {
          const temp = event.loadComponent.split(':'); // we may be rendering the same component multiple times
          let cid = temp[0];
          this.waitForComponentId = null;
          this.currentExportContext = event.context;

          if (temp.length === 2 && temp[0] === 'summaryController') {
            if (temp[1] === 'overlay' || temp[1] === 'mapAndMetrics') {
              cid = 'summaryController:mapAndMetrics';
            } else {
              // second component in layout, the tactic tables
              cid = 'summaryController:tables';
            }
          }

          let cpnt = this.componentsById[cid];
          if (!cpnt) {
            cpnt = this.componentsByInternalId[cid];
          }

          if (!cpnt) {
            this.sendPageEvent({
              type: 'componentNotFound',
              index: event.loadComponent,
            });
            return;
          }

          cpnt._id_version = event.loadComponent; // for when we render it multiple times
          if (event.size) {
            cpnt.size = event.size;
          }
          if (cpnt.type === 'chart') {
            switch (cpnt.component) {
              case 'Header':
              case 'Footer':
              case 'AdPortalHeader':
              case 'AdPortalFooter':
              case 'summaryMap':
              case 'dmaZipMap':
              case 'genericMap':
              case 'geofenceMap':
              case 'stationMap':
              case 'tacticTables':
              case 'genericPie':
              case 'genericLineChart':
              case 'genericLineBarChartNew':
              case 'lineAndBarChart':
              case 'progressBarTable':
              case 'genericTopMetrics':
              case 'genericTable':
              case 'siteImpactTable':
              case 'genericBarChart':
              case 'sideSummary':
              case 'campaignOverview':
              case 'textCampaignSummary':
              case 'campaignStats':
              case 'EmptyScheduledExport':
              case 'mapSummaryOverlay':
              case 'mapWrapper':
              case 'summaryController':
              case 'targetingList':
              case 'googlevideoSummaryView':
              case 'slingNetworkLogosList':
              case 'tableList':
              case 'vennDiagram':
                // this.waitForComponentRender(cpnt);
                this.resetAndWaitForComponentRender(cpnt);
                break;
              case 'benchmarksChart':
              default:
                this.sendPageEvent({
                  type: 'componentReady',
                  id: cpnt._id,
                  warning: 'todo: how to render ' + cpnt.component,
                });
                // eslint-disable-next-line no-console
                console.log('how to render', cpnt);
                break;
            }
          } else if (cpnt.component) {
            switch (cpnt.component) {
              case 'AllCampaignNames':
              case 'Header':
              case 'Footer':
              case 'AdPortalHeader':
              case 'AdPortalFooter':
                this.resetAndWaitForComponentRender(cpnt);
                break;
              default:
                if (this.browserDebug) {
                  // eslint-disable-next-line no-console
                  console.log('loadComponent is component, but not a chart?', event, cpnt);
                }

                this.currentExportComponent = cpnt;
                this.waitForRender(() => {
                  this.sendPageEvent({
                    type: 'componentReady',
                    id: cpnt._id,
                  });
                }, cpnt.captureDelayMS);
                break;
            }
          } else if (cpnt.text) {
            if (!this.exportData || !this.exportData.advertiser) {
              this.sendPageEvent({
                type: 'componentReady',
                id: cpnt._id,
                error: 'missing context to render text template: ' + cpnt.text,
              });
              return;
            }
            const cntx = {
              currentDateTime: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
              ...this.exportData,
            };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let text: any = '';
            if (Array.isArray(cpnt.text)) {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              text = cpnt.text.map((textEl: any) => {
                if (typeof textEl === 'string') {
                  return utils.renderTemplate(textEl, cntx);
                }
                if (typeof textEl === 'object' && textEl.text) {
                  return {
                    ...textEl,
                    text: utils.renderTemplate(textEl.text, cntx),
                  };
                }
                return textEl;
              });
            } else {
              text = utils.renderTemplate(cpnt.text, cntx);
            }
            this.sendPageEvent({
              type: 'componentReady',
              id: cpnt._id,
              text,
            });
          } else {
            // eslint-disable-next-line no-console
            console.log('client does not know how to handle this component', cpnt);
            this.sendPageEvent({
              type: 'componentReady',
              error: 'client does not know how to handle this component',
              id: cpnt._id,
            });
          }
        }

        if (event.simpleRender) {
          if (this.exportData?.layout?.fileType === 'XLS') {
            this.waitForRender(() => {
              this.sendPageEvent({
                type: 'completeExport',
              });
            });
            return;
          }
          // if (this.exportData?.tab === 'empty') {
          //   this.sendPageEvent({
          //     type: 'componentReady',
          //     text: 'Not data for the selected report date range: mm/dd/yyyy to mm/dd/yyyy',
          //   });
          //   return;
          // }
          this.errorMessage = 'unhandled export';
          this.sendPageEvent({ type: 'error', message: 'unhandled export', details: event });
        }

        if (event.dataCheck) {
          const checkResult = utils.propertyByPath(
            {
              ...this.exportData,
              ...event.context,
            },
            event.dataCheck,
          );
          this.sendPageEvent({ type: 'dataChecked', results: !!checkResult, pageIndex: event.pageIndex });
        }

        if (event.action) {
          switch (event.action) {
            case 'computeExportSubtasks':
              this.computeExportSubtasks(event);
              break;
            default:
              // eslint-disable-next-line no-console
              console.log(`receiveServerEvent unhandled event action`, event);
              break;
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(`receiveServerEvent error ${err.message} ${JSON.stringify(event)}`);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    waitForRender(callback: any, delay: any): void {
      // trying different tricks of nextTicks and setTimeout
      // to find a safe, but as fast as possible
      // way to ensure the page is rendered
      // before telling the server to process
      if (!delay || typeof delay !== 'number') {
        delay = 1;
      }
      this.$nextTick(() => {
        setTimeout(callback, delay);
      });
    },
    goDebugNextComponent(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const w: any = window as any;
      w[eventFromExportToPageHandlerName](this.debugNextComponent);
    },
  },
});
